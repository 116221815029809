import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.div`
  && {
		color: ${({theme}) => theme.grey};
	}
`

const Footer = (props) => (
	<StyledFooter {...props} className="pb-8">
		<div className="flex flex-row justify-center mt-3">
      © 2020 Utter. All rights reserved.
		</div>
	</StyledFooter>
)
Footer.propTypes = {

}

export default Footer
