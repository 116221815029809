import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Dialog, Typography, Toggle } from './primitives'
import { Context } from './Context'

const _Dialog = styled(Dialog)`
  background: ${({theme}) => theme.name === 'light' ? 'rgba(222,222,227,1)' : 'rgba(18,18,20,1)'} !important;
`

const OptionsDialog = ({ isOpen, onClose }) => {

	const {
		sfxOn: sfxOnCtx,
		musicOn: musicOnCtx,
		testModeOn: testModeOnCtx
	} = useContext(Context)
	const [ sfxOn, setSfxOn ] = sfxOnCtx
	const [ musicOn, setMusicOn ] = musicOnCtx
	const [ testModeOn, setTestModeOn ] = testModeOnCtx
  
	return (
		<_Dialog isOpen={isOpen} onClose={onClose} showHeader={false}>
			<div className="flex flex-col p-10">
				<Typography variant="h5" className="mx-8 mb-8 text-left" weight="bold">
          Options
				</Typography>
          
				<div className="flex w-full">
					<div className="flex flex-col mx-8">
						<Typography variant="body" className="mb-4 text-center">
              Music
						</Typography>
						<Toggle 
							checked={musicOn} 
							onClick={() => setMusicOn(prev => !prev)}
						/>
					</div>
					<div className="flex flex-col mx-8">
						<Typography variant="body" className="mb-4 text-center">
             SFX
						</Typography>
						<Toggle 
							checked={sfxOn}
							onClick={() => setSfxOn(prev => !prev)}
						/>
					</div>
				</div>
				<div className="flex mt-10 w-full">
					<div className="flex flex-col mx-8">
						<Typography variant="body" className="mb-4 text-center">
              Test Mode
						</Typography>
						<Toggle 
							checked={testModeOn} 
							onClick={() => setTestModeOn(prev => !prev)}
						/>
					</div>
				</div>
			</div>
		</_Dialog>
	)
}

OptionsDialog.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func
}

export default OptionsDialog
