import React, { useState, useContext, useEffect } from 'react'
import { FirebaseContext } from '../Firebase'


export function withFirebaseAuthentication(Component) {
	return function withFirebaseAuthenticationComponent(props) {
		const {firebase} = useContext(FirebaseContext)
		const [signedIn, setSignedIn] = useState(false)
		const [loading, setLoading] = useState(true)
		const [user, setUser] = useState({})

		useEffect(() => {
			if (firebase) {
				firebase.auth.onAuthStateChanged(
					(user) => { setSignedIn(Boolean(user)); setLoading(false); setUser(user)}
				)
			} 
		}, [firebase])
		
		return (
			<>
				<Component 
					signedIn={signedIn} 
					signInLoading={loading} 
					user={user}
					{...props} />
			</>
		)
	}
}

export default withFirebaseAuthentication