import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import  Link from 'gatsby-link'
import { Navbar, Alignment, Popover, Position } from '@blueprintjs/core'
import { MenuIcon, Menu, Badge, Pane, CogIcon } from 'evergreen-ui'

import { FirebaseContext } from '../components/Firebase'
import { ThemeProvider } from '../Layout'
import { ThemeToggle, Typography, Button } from '../components/primitives'
import OptionsDialog from '../components/OptionsDialog'
import { withFirebaseAuthentication } from '../components/hocs/withFirebaseAuthentication'


const MenuButton = styled(Button)`
  background: none !important;
  box-shadow: none !important;
`
const Container = styled(Navbar)`
	&& {
		box-shadow: 0 0 1px rgba(67,90,111,.47), 0 2px 4px -2px rgba(67,90,111,.3);
		background: ${({theme}) => theme.grey_6};
		position: ${({position}) => position};
		height: 6rem;
		padding-top: 1.2rem; 

		/* Medium devices (tablets, 768px and up) */
		@media (min-width: 768px) { 
			${MenuButton} {
				display: none !important;
			}
  	}
	}
`
const NavMenu = styled(Popover)`
	.bp3-popover-content {
		background: ${({theme}) => theme.grey_6};
	}
`
const NavLinks = styled.div`
	display: none;
	/* Medium devices (tablets, 768px and up) */
	@media (min-width: 768px) { 
		display: flex !important;
	}
`
const LogoLink = styled.div`
	display: flex !important;
`
const ThemedLink = styled(Link)`

`

const NavbarLinks = [
	{ name: 'Home', link: '/' },
	{ name: 'New game', link: '/configuration' },
	{ name: 'Contact', link: '/contact' }
]

const _ = ({
	page, fixed, position, signedIn, ...props
}) => {
	const { theme: themeCtx } = useContext(ThemeProvider.Context)
	const {firebase} = useContext(FirebaseContext)

	const setTheme = themeCtx[1]

	const [openOptions, setOpenOptions] = useState(false)

	const logOut = () => {
		if(firebase) {
			firebase.doSignOut()
			navigate('/login')
		}
	}
	
	return (
		<Container {...props} fixedToTop={fixed} position={position}>
			<Navbar.Group align={Alignment.LEFT} className="pl-8">
				<LogoLink>
					<ThemedLink to="/">
						<Pane display="flex" alignItems="center">
							<Typography variant="body" weight="bold" className="flex px-2 mb-0 align-center">
								<span>Utter.</span>
							</Typography>
							<Badge color="yellow">
								<small>Beta 0.0.19</small>
							</Badge>
						</Pane>
					</ThemedLink>
				</LogoLink>
			</Navbar.Group>
			<Navbar.Group align={Alignment.RIGHT} className="pl-4">
				<NavLinks>
					{
						NavbarLinks.map(({ name, link }, i) => (
							<ThemedLink key={i} to={link} state={link === '/configuration' ? { newGame: true } : null}>
								<Typography variant="body" className="px-2 m-0" weight={page === name ? 'black' : 'bold'}>
									{ name }
								</Typography>
							</ThemedLink>
						))
					}
					<ThemedLink to={ signedIn ? '/' : '/login' } onClick={() => ( signedIn ? logOut() : null )}>
						<Typography variant="body" className="px-2 m-0" weight="bold">
							{ signedIn ? 'Logout' : 'Login' }
						</Typography>
					</ThemedLink>	
					<Typography 
						variant="body"
						className="flex items-center px-2 m-0" 
						onClick={() => setOpenOptions(true)}>
						<CogIcon/>
					</Typography>
				</NavLinks>
				<div>
					<NavMenu
						position={Position.BOTTOM_LEFT}
						minimal={true}
						usePortal={false}
						content={
							<Menu>
								<Menu.Group>
									{
										NavbarLinks.map(({ name, link }, i) => (
											<Menu.Item key={i}>				
												<ThemedLink to={link} state={link === '/utter' ? { newGame: true } : null}>
													<Typography variant="body" className="px-2" weight={page === name ? 'bold' : 'normal'}>
														{ name }
													</Typography>
												</ThemedLink>
											</Menu.Item>
										))
									}
									<Menu.Item>				
										<ThemedLink to={ signedIn ? '/' : '/login' } onClick={() => ( signedIn ? logOut() : null )}>
											<Typography variant="body" className="px-2" weight="normal">
												{ signedIn ? 'Logout' : 'Login' }
											</Typography>
										</ThemedLink>
									</Menu.Item>	
									<Menu.Item onSelect={() => setOpenOptions(true)}>				
										<Typography variant="body" className="px-2 m-0">
										Options
										</Typography>
									</Menu.Item>			
								</Menu.Group>
							</Menu>
						}>
						<MenuButton><MenuIcon/></MenuButton>
					</NavMenu>
				</div>
				<ThemeToggle className="ml-4" onChange={() => setTheme(lastThemeType => (
					lastThemeType === 'light' ? 'dark' : 'light'
				))} />
			</Navbar.Group>
			<OptionsDialog isOpen={openOptions} onClose={() => {setOpenOptions(false)}}/>
		</Container>
	)
}
_.propTypes = {
	page: PropTypes.string,
	/** Specifies whether positioning should be fixed or relative */
	fixed: PropTypes.bool,
	background: PropTypes.string,
	boxshadow: PropTypes.string,
	position: PropTypes.string,
	signedIn: PropTypes.bool
}
_.defaultProps = {
	background: '#FFF',
	boxshadow: '0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.1);',
	position: 'relative'
}

export default withFirebaseAuthentication(_)
